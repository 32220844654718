// Replace with loop.

@font-face
  font-display: swap
  font-family: Trex-Sans
  font-style: normal
  font-weight: 300
  src: url("./fonts/source-sans-pro-v11-latin-300.woff2") format("woff2"), url("./fonts/source-sans-pro-v11-latin-300.woff") format("woff")

@font-face
  font-display: swap
  font-family: Trex-Sans
  font-style: italic
  font-weight: 300
  src: url("./fonts/source-sans-pro-v11-latin-300italic.woff2") format("woff2"), url("./fonts/source-sans-pro-v11-latin-300italic.woff") format("woff")


@font-face
  font-display: swap
  font-family: Trex-Sans
  font-style: normal
  font-weight: 400
  src: url("./fonts/source-sans-pro-v11-latin-regular.woff2") format("woff2"), url("./fonts/source-sans-pro-v11-latin-regular.woff") format("woff")

@font-face
  font-display: swap
  font-family: Trex-Sans
  font-style: italic
  font-weight: 400
  src: url("./fonts/source-sans-pro-v11-latin-italic.woff2") format("woff2"), url("./fonts/source-sans-pro-v11-latin-italic.woff") format("woff")

@font-face
  font-display: swap
  font-family: Trex-Sans
  font-style: normal
  font-weight: 600
  src: url("./fonts/source-sans-pro-v11-latin-600.woff2") format("woff2"), url("./fonts/source-sans-pro-v11-latin-600.woff") format("woff")

@font-face
  font-display: swap
  font-family: Trex-Sans
  font-style: italic
  font-weight: 600
  src: url("./fonts/source-sans-pro-v11-latin-600italic.woff2") format("woff2"), url("./fonts/source-sans-pro-v11-latin-600italic.woff") format("woff")
