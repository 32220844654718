@import "components/App/css/variables"
@import "components/App/css/breakpoints"
@import "components/App/css/debug"
@import "components/App/css/html"
@import "components/App/css/fonts"


*,
*:before,
*:after
  box-sizing: inherit
  scroll-behavior: smooth

html
  box-sizing: border-box
  font-size: $fontSizes-default-base
  line-height: $lineHeights-default-base
  font-family: $fontFamilies-default-sans
  font-weight: $fontWeights-default-sans-light
  @media #{$breakpoint-m}
    font-size: $fontSizes-m-base
    line-height: $lineHeights-m-base
  @media print
    font-size: 9.5pt

    scroll-behavior: smooth
    -webkit-print-color-adjust: exact !important
    print-color-adjust: exact !important

  & body
    margin: 0
    @media print
      height: auto
      word-break: break-word
      hyphens: auto

  & figure,
  & img
    @media print
      page-break-inside: avoid

  & .DayPickerInput
    line-height: 1

a
  cursor: pointer

.hiddenAd
  display: none !important

.popper-box
  display: none
  padding: 0.44rem
  background: #fff
  box-shadow: 0 0 4px 1px #9b9b9b
  z-index: 991
  max-width: 40vw

.popper-box[data-show]
  display: block

.hide
  @media print
    display: none
