@import "@globals/breakpoints"
@import "@globals/variables"
@import "@globals/grid"

$Menu--default--padding: 1.6rem

.Spinner_root
  width: 4rem
  height: 4rem
  position: fixed
  box-shadow: 0 0 4px 1px #9b9b9b
  background: black
  border-radius: 50%
  z-index: 1001
  right: $Menu--default--padding
  bottom: $Menu--default--padding

  @include grid-container
  flex-direction: column
  justify-content: center

  @media #{$breakpoint-m}
    display: block
    width: initial
    height: initial
    right: 5%
    bottom: 3%
    margin: 2rem 0
    padding: .8em

.Spinner,
.SpinnerInner
  position: relative
  box-sizing: border-box


.Spinner
  display: block
  font-size: 0
  color: black
  width: 1.33rem
  margin: 0 auto


.SpinnerInner
  display: inline-block
  float: none
  border: solid white

  width: 1.33rem
  height: 1.33rem
  background: transparent
  border-bottom-color: transparent
  border-radius: 100%
  animation: clip-rotate 0.75s linear infinite


/*
 * Animation
 */
@keyframes clip-rotate
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)


