@import 'variables'
@import 'breakpoints'

.h1, h2, .h2, h3, .h3, h4, .h4
  font-family: $fontFamilies-default-sans
  font-weight: $fontWeights-default-sans-semibold
  line-height: 1.1

.h1-small
  font-weight: $fontWeights-default-sans-regular
  font-size: calc(16 / $htmlSizes-default * 1rem)
  text-transform: uppercase
  letter-spacing: 0.02em
  @media #{$breakpoint-m}
    font-size: calc(15 / $htmlSizes-m * 1rem)

.h1-underline
  &::after
    content: ""
    display: block
    width: 3rem
    height: 2px
    background-color: $colors-gray
    margin-top: 0.9rem
    margin-bottom: 1rem

h1,
.h1
  font-size: calc($fontSizes-default-h1 / $htmlSizes-default * 1rem)
  font-weight: $fontWeights-default-sans-light
  line-height: calc($lineHeights-default-h1 / $fontSizes-default-h1)
  margin-top: 0.25em
  margin-bottom: 0.65em
  @media #{$breakpoint-m}
    font-size: calc($fontSizes-m-h1 / $htmlSizes-m * 1rem)
    line-height: calc($lineHeights-m-h1 / $fontSizes-m-h1)

h2,
.h2
  font-size: calc($fontSizes-default-h2 / $htmlSizes-default * 1rem)
  margin-top: 1.7em
  margin-bottom: 0.7em
  @media #{$breakpoint-m}
    font-size: calc($fontSizes-m-h2 / $htmlSizes-m * 1rem)

h3,
.h3,
h4,
.h4
  margin-top: 0.9em
  margin-bottom: 0.9em

h3,
.h3
  font-size: calc($fontSizes-default-h3 / $htmlSizes-default * 1rem)
  @media #{$breakpoint-m}
    font-size: calc($fontSizes-m-h3 / $htmlSizes-m * 1rem)

.h3-small
  font-weight: $fontWeights-default-sans-light
  font-size: calc(26 / $htmlSizes-default * 1rem)
  @media #{$breakpoint-m}
    font-size: calc(30 / $htmlSizes-m * 1rem)

h4, .h4
  font-size: calc($fontSizes-default-h4 / $htmlSizes-default * 1rem)
  @media #{$breakpoint-m}
    font-size: calc($fontSizes-m-h4 / $htmlSizes-m * 1rem)

p
  font-weight: $fontWeights-default-sans-light
  margin-top: 0
  margin-bottom: 0.55em

ul
  padding-left: 0

ul ul
  padding-left: 2em

ul.none,
ul.none ul
  list-style: none

figure
  margin: 0

a
  color: inherit
  text-decoration: underline dotted
  -webkit-text-decoration: underline dotted

  text-decoration-skip-ink: none

  &:hover
    color: $colors-primary
    text-decoration: underline
    -webkit-text-decoration: underline

input[type="submit"],
button
  background-color: white
  cursor: pointer
  font-weight: $fontWeights-default-sans-semibold
  padding: 0.7rem 1.5rem
  border: 1px solid black

  &:hover
    background-color: $colors-primary

$dropdown_half_height: 0.9em
$dropdown_arrow_size: 6px

select,
option
  font-size: inherit
  font-family: inherit
  font-weight: inherit

select
  width: 100%
  height: calc($dropdown_half_height * 2)
  overflow: hidden
  margin: 0
  padding-left: 0.5em
  padding-right: 2rem
  box-sizing: border-box
  appearance: none
  outline: 0
  border-radius: 0
  border: 1px none

  &
    background-image: linear-gradient(45deg, transparent 50%, gray 50%), linear-gradient(135deg, gray 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc)
    background-position: calc(100% - 0.6em - $dropdown_arrow_size) calc($dropdown_half_height - $dropdown_arrow_size), calc(100% - 0.6em) calc($dropdown_half_height - $dropdown_arrow_size), calc(100% - 1.8em) 0
    background-size: $dropdown_arrow_size $dropdown_arrow_size, $dropdown_arrow_size $dropdown_arrow_size, 1px 105%
    background-repeat: no-repeat

  &:focus
    background-image: linear-gradient(45deg, green 50%, transparent 50%), linear-gradient(135deg, transparent 50%, green 50%), linear-gradient(to right, #ccc, #ccc)
    background-position: calc(100% - 0.6em) calc($dropdown_half_height - $dropdown_arrow_size), calc(100% - 0.6em - $dropdown_arrow_size) calc($dropdown_half_height - $dropdown_arrow_size), calc(100% - 1.8em) 0
    background-size: $dropdown_arrow_size $dropdown_arrow_size, $dropdown_arrow_size $dropdown_arrow_size, 1px 105%
    background-repeat: no-repeat
    border-color: green

  &:-moz-focusring
    text-shadow: 0 0 0 #000

$--input_height: 1.8em

input
  margin: 0
  font-size: inherit
  font-family: inherit
  font-weight: inherit
  box-sizing: border-box
  border: none
  border-radius: 0
  outline: 0
  background: 0

input:focus
  outline: 0

input[type="checkbox"],
input[type="radio"]
  display: inline
  margin-right: 0.5rem

input[type="text"],
input[type="email"],
input[type="password"]
  height: $--input_height
  width: 100%
  border: 1px solid black
  padding-left: 0.5em

input[type="submit"]
  margin-bottom: 5rem
  margin-top: 2rem

// https://stackoverflow.com/questions/2918707/turn-off-iphone-safari-input-element-rounding
// iOS
textarea,
input[type="text"],
input[type="button"],
input[type="submit"]
  -webkit-appearance: none
  color: black
  border-radius: 0
